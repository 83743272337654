import {Button, Grid, GridRow} from "semantic-ui-react";
import React from "react";
import {observer} from "mobx-react-lite";
import {InformationPanelItem} from "../Dashboard/InformationPanelItem";
import NewStoreField from "./NewStoreField";
import storesFieldValuesStorage from "../../Stores/StoresSettingsStorage";
import StoreSettingsStorage from "../../Stores/StoreSettingsStorage";
import PriorityField from "./PriorityField";
import skladStore from "../../Stores/SkladStore";
import FieldWithFieldIdSelector from "../Shared/FieldWithFieldIdSelector";
import FieldValueRaw from "../Shared/FieldValueRaw";
import FieldValue from "../Shared/FieldValue";
import FieldWithCheckbox from "../Shared/FieldWithCheckbox";

function StoreComponent(props: { store: StoreSettingsStorage }) {
    const {store} = props;
    return <>
        <InformationPanelItem header={store.name}>
            <Grid>
                <FieldWithFieldIdSelector name={'Цена'} value={store.priceFieldId}
                                          fields={skladStore.salePrices}
                                          placeholder={'Выберите цену для товаров данного склада'}
                                          tip={'Поле цены для данного склада'}
                                          onChange={value => store.changePriceFieldId(value)}/>
                <FieldWithFieldIdSelector name={'Описание'} value={store.descriptionFieldId}
                                          fields={skladStore.customFields}
                                          tip={'Поле описания для данного склада'}
                                          placeholder={'Выберите описание для товаров данного склада'}
                                          onChange={value => store.changeDescriptionFileId(value)}/>
                <PriorityField priority={store.priority}
                               onChange={value => store.changePriority(value)}
                               ignoreLowerStores={store.ignoreLowerPriorityStores}
                               onChangeIgnoreLowerStores={value => store.changeIgnoreLowerPriorityStores(value)}
                />
                <FieldWithCheckbox name={"Разрешить совместный остаток при отрицательном значении"}
                                   tip={"Данная опция позволяет суммировать остаток склада, как при положительном, так и при  отрицательном значении\n" +
                                       "Пример:\n" +
                                       "У вас в настройках XML файла выбрано два склада\n" +
                                       "Склад 1: Остаток: 0 Резерв: 3\n" +
                                       "Склад 2: Остаток:10 Резерв:0\n" +
                                       "Если данная опция активирована, в выгрузку на Авито попадет количество: 7шт.\n" +
                                       "Если данная опция НЕ активирована, в выгрузку на Авито попадет количество 10шт."}
                                   value={store.canUseNegativeStock}
                                   width={9}
                                   onChange={value => store.changeCanUseNegativeStock(value)}
                />
                {storesFieldValuesStorage.fields
                    .map(field => <FieldValueRaw key={field.id}
                                                 field={field}
                                                 fieldValue={store.getOrCreateFieldValue(field.id)}
                                                 onSave={() => store.save()}/>)}
                <GridRow>
                    <NewStoreField/>
                    <FieldValue>
                        <Button color={'teal'} floated={'right'}
                                onClick={() => store.save()}
                                disabled={!store.hasChanges}
                                loading={store.saving}>Сохранить</Button>
                    </FieldValue>
                </GridRow>

            </Grid>
        </InformationPanelItem>
    </>
}

export default observer(StoreComponent);
