import {Label} from "semantic-ui-react";
import React from "react";
import {observer} from "mobx-react-lite";
import FieldEditorStore from "../../Stores/FieldEditorStore";
import EditorInput from "./EditorInput";

function FieldEditor(props: { store: FieldEditorStore }) {
    const {store} = props;

    return <>
        <EditorInput value={store.name}
                     onEdit={value => store.changeName(value)}
                     cancel={() => store.cancel()}
                     save={() => store.save()}
                     canSave={store.name !== undefined && store.hasChanges}
                     isSaving={store.isSaving}
        />
        <Label style={{marginTop: '5px'}} color={'teal'}> Для удаления напишите "удалить"</Label>
    </>
}

export default observer(FieldEditor);
