import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Button, Grid} from "semantic-ui-react";
import CategoryV2SelectorTree from "../../Pages/Templates/V2/CategoryV2SelectorTree";
import categoriesStore from "../../Stores/V2/CategoriesStore";
import CategoryEditorPage from "./CategoryEditorPage";
import categoryLookupsStore from "./CategoryLookupsStore";
import categoryEditorPageStore from "./CategoryEditorPageStore";
import CategoryFieldsPage from "./CategoryFieldsPage";

function AdminTemplateV2Page() {
    useEffect(() => {
        void categoryLookupsStore.load();
    }, []);
    return <Grid columns={2} divided>
        <Grid.Row>
            <Grid.Column width={7}>
                <CategoryEditorPage/>
            </Grid.Column>
            <Grid.Column width={6}>
                Дерево категорий
                <CategoryV2SelectorTree/>
                {categoriesStore.lastSelectedCategory &&
                    <Button floated={'right'}
                            onClick={() => categoryEditorPageStore.init(categoriesStore.lastSelectedCategory)}
                            basic>Выбрать</Button>
                }
                <Button basic floated={'right'} onClick={() => categoriesStore.reload()}>Обновить</Button>
            </Grid.Column>
            <Grid.Column width={3}>
                <Button fluid
                        basic
                        style={{marginTop: '20px'}}
                        floated={'right'} onClick={() => categoriesStore.recalculatePaths()}>Пересчитать пути</Button>
            </Grid.Column>
        </Grid.Row>
        {categoryEditorPageStore.editor && <Grid.Row>
            <CategoryFieldsPage fields={categoryEditorPageStore.editor.fields}/>
        </Grid.Row>}
    </Grid>
}

export default observer(AdminTemplateV2Page);