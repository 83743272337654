import {computed, observable} from "mobx";
import TemplateFieldStore from "./TemplateFieldStore";
import api from "../../Services/Api";

class TemplateStore {
    id: string;
    @observable name: string;
    categoryPath: string;
    categoryDocumentationLik?: string;
    fields: TemplateFieldStore[];
    @observable isSaving: boolean = false;
    @observable requiredImages: boolean;
    @observable requiredPrice: boolean;
    @observable imageUrls: string;
    @observable imageRange: string;
    onSuccessSaving: () => void;

    @observable settingsVisible: boolean = false;
    @observable hasChanges: boolean = false;
    @observable historyIsVisible: boolean = false;

    constructor(id: string, name: string, 
                categoryPath: string,
                fields: TemplateFieldStore[],
                requiredImages: boolean,
                requiredPrice: boolean,
                imageUrls: string,
                imageRange: string,
                onSuccessSaving: () => void,
                categoryDocumentationLik?: string) {
        this.id = id;
        this.name = name;
        this.categoryPath = categoryPath;
        this.categoryDocumentationLik = categoryDocumentationLik;
        this.fields = fields;
        this.onSuccessSaving = onSuccessSaving;
        this.requiredImages = requiredImages;
        this.requiredPrice = requiredPrice;
        this.imageUrls = imageUrls;
        this.imageRange = imageRange;
    }

    async save() {
        if (!this.canSave) {
            return;
        }
        this.setIsSaving(true);
        try {
            const result = await api.post('templateV2/save', {
                id: this.id,
                name: this.name,
                requiredImages: this.requiredImages,
                requiredPrice: this.requiredPrice,
                imageUrls: this.imageUrls,
                imageRange: this.imageRange,
                fields: this.fields.map(f => {
                    return {
                        id: f.id,
                        value: f.value ?? ''
                    }
                })
            });
            if (result.success) {
                void this.onSuccessSaving();
            }
        } finally {
            this.setIsSaving(false);
        }
    }

    @computed get canSave(): boolean {
        const allRequiredFieldsFilled = this.fields.every(x => !x.isRequired || x.value);
        if (!allRequiredFieldsFilled) {
            return false;
        }
        return this.hasChanges || this.fields.some(x => x.hasChanges);
    }

    setIsSaving(isSaving: boolean) {
        this.isSaving = isSaving;
    }

    changeRequiredImages(value: boolean) {
        this.requiredImages = value;
        this.hasChanges = true;
    }

    changeRequiredPrice(value: boolean) {
        this.requiredPrice = value;
        this.hasChanges = true;
    }

    changeImageRange(value: string) {
        this.imageRange = value;
        this.hasChanges = true;
    }

    changeImageUrls(value: string) {
        this.imageUrls = value;
        this.hasChanges = true;
    }

    showSettings() {
        this.settingsVisible = true;
    }

    switchHistoryVisible() {
        this.historyIsVisible = !this.historyIsVisible;
    }
}

export default TemplateStore;