import IHasIdAndName from "../Entities/IHasIdAndName";
import api from "../Services/Api";

class TemplateLookupStore {
    templates: IHasIdAndName[] = []
    async load() {
        const result = await api.get('template/list/all');
        if (result.success) {
            this.templates = result.data;
        }
    }
}

const templateLookupStore = new TemplateLookupStore();
export default templateLookupStore;