import {observable} from "mobx";
import api from "../../Services/Api";

export default class CategoryCreatorStore {
    @observable name?: string;
    @observable isCreating: boolean = false;
    @observable creationStarted: boolean = false;
    @observable parentCategoryId?: string;
    onCreated: () => void;
    
    constructor(onCreated: () => void) {
        this.onCreated = onCreated;
    }

    async create() {
        this.setIsCreating(true);
        try {
            const result = await api.post('categoryV2/create', {
                name: this.name,
                parentCategoryId: this.parentCategoryId
            });

            if (result.success) {
                this.updateName(undefined);
                this.onCreated();
                // if (this.lastSelectedCategory) {
                //     void this.lastSelectedCategory.loadChildren();
                // } else {
                //     await this.load();
                // }
            }
        } finally {
            this.setIsCreating(false);
        }
    }

    startCreation() {
        this.creationStarted = true;
    }

    stopCreation() {
        this.updateName(undefined);
        this.creationStarted = false;
    }

    updateName(name?: string) {
        this.name = name;
    }
    
    setParentId(parentId?: string) {
        this.parentCategoryId = parentId;
    }

    private setIsCreating(value: boolean) {
        this.isCreating = value;
    }
}