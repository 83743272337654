import {Button, Input} from "semantic-ui-react";
import React from "react";

function EditorInput(props: {
    value?: string,
    onEdit: (value: string) => void,
    cancel: () => void,
    save: () => void,
    canSave: boolean,
    isSaving: boolean,
    readonly?: boolean,
    ignoreOnBlur?: boolean,
    disableAutoFocus?: boolean,
    disabeSavingByEnter?: boolean
}) {
    const {
        readonly, cancel, save,
        value, onEdit,
        canSave, isSaving,
        ignoreOnBlur,
        disableAutoFocus,
        disabeSavingByEnter
    } = props;
    return <Input fluid action autoFocus={!disableAutoFocus} onBlur={() => !ignoreOnBlur && cancel()}>
        <input
            readOnly={readonly || isSaving}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    if (disabeSavingByEnter) {
                        return;
                    }
                    save();
                }
                if (e.key === 'Escape') {
                    cancel();
                }
            }}
            value={value ?? ''}
            onChange={(e) => onEdit(e.target.value)}/>
        {canSave && <Button icon={'save'}
                            onMouseDown={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation()
                            }}
                            loading={isSaving}
                            onClick={() => save()}/>}
        <Button icon={'cancel'}
                onClick={() => cancel()}/>
    </Input>
}

export default EditorInput;