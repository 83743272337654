import React, {useEffect} from "react";
import store from "./OrderWidgetStore";
import {observer} from "mobx-react-lite";
import ChatComponent from "../../Pages/Messenger/ChatComponent";
import {Button} from "semantic-ui-react";

function OrderWidgetPage(props: { contextKey: string }) {
    useEffect(() => {
        function handleEvent(e: any) {
            const data = e.data;
            if (!data) return;
            const eventType = data.name;
            const objectId = data.objectId;

            if (eventType === "Open") {
                const helpWidget = document.getElementById("fsw-btn");
                if (helpWidget) {
                    helpWidget.style.display = "none";
                }
                void store.init(props.contextKey, objectId);
            }
        }

        window.addEventListener('message', handleEvent);
        return function cleanup() {
            window.removeEventListener('message', handleEvent);
            store.cleanup();
        }
    }, []);
    if (store.error) {
        return <>{store.error}</>
    }

    if (store.isLoading) {
        return <>Загрузка...</>
    }

    if (!store.chat || !store.avitoUser) {
        return <Button basic onClick={() => store.authAndLoad()}>Загрузить чат</Button>
    }

    return <div style={{maxHeight: '100vh', minHeight: '90vh', marginTop: '0px'}}>
        <ChatComponent messagesDivHeight={'300px'} chat={store.chat!} accountUser={store.avitoUser!}/>
    </div>
}

export default observer(OrderWidgetPage);