import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react-lite";
import {Dropdown, DropdownDivider, DropdownItem, DropdownMenu, Input, Menu, MenuItem} from "semantic-ui-react";
import contextMenuStore from "./ContextMenuStore";
import fieldsLookupStorage from "../../../../Stores/FieldsLookupStorage";
import './Style.css'

function ContextMenu() {
    const menuRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                contextMenuStore.hide();
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => document.removeEventListener("click", handleClickOutside);
    }, []);

    if (!contextMenuStore.visible) {
        return <></>
    }

    return <div ref={menuRef}
                style={{
                    top: contextMenuStore.y + 'px',
                    left: contextMenuStore.x + 'px',
                    position: 'fixed',
                    zIndex: 999,
                }}>
        <Menu vertical>
            {fieldsLookupStorage.baseFields.length > 0 &&
                <Dropdown id='dropdown-menu-header'
                          item
                          scrolling
                          text='Базовые поля из МойСклад'
                          onClick={e => e.stopPropagation()}>
                    <DropdownMenu id='dropdown-menu'>
                        {fieldsLookupStorage.baseFields.map(f =>
                            <DropdownItem
                                key={f.id}
                                onClick={() => contextMenuStore.insertTextAtCaret(`[!${f.name}!]`)}
                            >
                                {f.name}
                            </DropdownItem>)}
                    </DropdownMenu>
                </Dropdown>
            }
            {fieldsLookupStorage.customFields.length > 0 &&
                <Dropdown id='dropdown-menu-header' scrolling item text='Дополнительные поля из МойСклад'
                          onClick={e => e.stopPropagation()}>
                    <DropdownMenu id='dropdown-menu'>
                        <Input icon='search'
                               iconPosition='left'
                               className='search'
                               onChange={e => contextMenuStore.changeCustomFieldsSearch(e.target.value)}
                               onClick={(e: React.MouseEvent) => e.stopPropagation()}
                               value={contextMenuStore.customFieldsSearch}
                        />
                        <DropdownDivider/>
                        {fieldsLookupStorage.customFields
                            .filter(x => !contextMenuStore.customFieldsSearch || x.name.toLowerCase()
                                .includes(contextMenuStore.customFieldsSearch.toLowerCase()))
                            .map(f =>
                                <DropdownItem
                                    key={f.id}
                                    onClick={() => contextMenuStore.insertTextAtCaret(`[$${f.name}$]`)}
                                >{f.name}</DropdownItem>)}
                    </DropdownMenu>
                </Dropdown>
            }
            {fieldsLookupStorage.characteristics.length > 0 &&
                <Dropdown id='dropdown-menu-header' scrolling item text='Характеристики модификаций из МойСклад'
                          onClick={e => e.stopPropagation()}>
                    <DropdownMenu id='dropdown-menu'>
                        {fieldsLookupStorage.characteristics.map(f => <DropdownItem
                            key={f.id}
                            onClick={() => contextMenuStore.insertTextAtCaret(`[$${f.name}$]`)}
                        >{f.name}</DropdownItem>)}
                    </DropdownMenu>
                </Dropdown>
            }
            {fieldsLookupStorage.prices.length > 0 &&
                <Dropdown id='dropdown-menu-header' scrolling item text='Цены продажи из МойСклад'
                          onClick={e => e.stopPropagation()}>
                    <DropdownMenu id='dropdown-menu'>
                        {fieldsLookupStorage.prices.map(f => <DropdownItem
                            key={f.id}
                            onClick={() => contextMenuStore.insertTextAtCaret(`[!${f.name}!]`)}
                        >{f.name}</DropdownItem>)}
                    </DropdownMenu>
                </Dropdown>
            }
            {fieldsLookupStorage.storeFields.length > 0 &&
                <Dropdown id='dropdown-menu-header' scrolling item text='Поля складов'
                          onClick={e => e.stopPropagation()}>
                    <DropdownMenu id='dropdown-menu'>
                        {fieldsLookupStorage.storeFields.map(f => <DropdownItem
                            key={f.id}
                            onClick={() => contextMenuStore.insertTextAtCaret(`[!${f.name}!]`)}
                        >{f.name}</DropdownItem>)}
                    </DropdownMenu>
                </Dropdown>
            }
            {fieldsLookupStorage.unloadFields.length > 0 &&
                <Dropdown id='dropdown-menu-header' scrolling item text='Поля файлов'
                          onClick={e => e.stopPropagation()}>
                    <DropdownMenu id='dropdown-menu'>
                        {fieldsLookupStorage.unloadFields.map(f => <DropdownItem
                            key={f.id}
                            onClick={() => contextMenuStore.insertTextAtCaret(`[%${f.name}%]`)}
                        >{f.name}</DropdownItem>)}
                    </DropdownMenu>
                </Dropdown>
            }
            <MenuItem
                name={"Копировать"}
                onClick={() => contextMenuStore.copyToClipboard()}
            />
            <MenuItem
                name={"Вставить"}
                onClick={() => contextMenuStore.pasteFromClipboard()}
            />
        </Menu>
    </div>
}

export default observer(ContextMenu);