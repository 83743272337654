import React from "react";
import TemplateStore from "../../../Stores/V2/TemplateStore";
import {observer} from "mobx-react-lite";
import {Button, Dropdown, Form, Grid, Header, Icon, Label, Message, Popup, TextArea} from "semantic-ui-react";
import {InformationPanelItem} from "../../Dashboard/InformationPanelItem";
import TextField from "../../Shared/TextField";
import contextMenuStore from "./ContextMenu/ContextMenuStore";
import FieldWithCheckbox from "../../Shared/FieldWithCheckbox";
import utils from "../../../Services/Utils";
import FieldSelector from "./FieldSelector";

function TemplateComponent(props: { template: TemplateStore }) {
    const {template} = props;
    return <>
        <TemplateHeader template={template}/>
        <TemplateFields template={template}/>
        <TemplateSettings template={template}/>
        <TemplateButtons template={template}/>
    </>
}

const TemplateHeader = observer((props: { template: TemplateStore }) => {
    const {template} = props;
    return <InformationPanelItem>
        <Header size={'medium'}>{template.name}</Header>
        Категория: {template.categoryPath}
        {template.categoryDocumentationLik && <div>
            <a target="_blank"
               rel="noopener noreferrer"
               href={template.categoryDocumentationLik}>Правила заполнения полей</a>
        </div>}
    </InformationPanelItem>
});

const TemplateFields = observer((props: { template: TemplateStore }) => {
    const {template} = props;
    return <InformationPanelItem>
        <Grid>
            {template.fields.map((field) => <>
                    <TextField key={field.id}
                               onContextMenu={(e: any) => openContextMenu(e, value => field.changeValue(value))}
                               required={field.isRequired}
                               name={field.name}
                               action
                               description={field.tag}
                               onChange={value => field.changeValue(value)}
                               value={field.value}>
                        {field.isText ?
                            <Form style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                                <TextArea
                                    onContextMenu={(e: any) => openContextMenu(e, value => field.changeValue(value))}
                                    rows={3}
                                    style={{minHeight: 100, maxHeight: '300px'}}
                                    value={field?.value} onChange={(_, data) => {
                                    field.changeValue(data.value as string)
                                }}/>
                            </Form>
                            : <input/>}

                        {field.possibleValues && field.possibleValues.length > 0 &&
                            <Dropdown
                                icon='angle down'
                                button
                                className={field.isText ? 'icon fix-height' : 'icon'}
                                direction='right'
                                style={{marginRight: '1px'}}
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Header content='Стандартные значения авито'/>
                                    <Dropdown.Menu scrolling style={{maxHeight: '150px'}}>
                                        {field.possibleValues.map((value, index) => (
                                            <Dropdown.Item key={index} text={value}
                                                           onClick={() => field.selectPossibleValue(value)}/>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        <FieldSelector isText={field.isText} onSelect={value => field.selectValue(value)}/>
                        {template.historyIsVisible && !field.historyIsVisible &&
                            <Button className={"fix-height"}
                                    style={{marginRight: '1px'}}
                                    loading={field.historyIsLoading}
                                    icon={'history'}
                                    onClick={() => void field.loadHistory()}/>
                        }
                        <Button as={'a'}
                                className={field.isText ? 'icon fix-height' : 'icon'}
                                icon={'world'}
                                href={field.helpUrl} target="_blank" rel="noopener noreferrer"/>
                        {field.tip && <Popup
                            position={'bottom right'}
                            content={field.tip}
                            trigger={<Button style={{marginLeft: '1px'}}
                                             icon='help'/>}/>}
                    </TextField>
                    {template.historyIsVisible && field.historyIsVisible &&
                        <Grid.Row>
                            <Grid.Column width={5}>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <Message onDismiss={() => field.hideHistory()}>
                                    <Message.Header style={{marginBottom: '10px'}}>Список изменений</Message.Header>
                                    {field.history.length > 0
                                        ? <Message.Content>
                                            {field.history.map(historyItem =>
                                                <p>
                                                    <Label
                                                        color={'blue'}>{utils.getLocalizedDateString(historyItem.createdOn)}</Label>
                                                    Изменено на:
                                                    <Label
                                                        color={'green'}>{historyItem.value ? historyItem.value : '-Пустое значение-'}</Label>
                                                </p>
                                            )}
                                        </Message.Content>
                                        : <Message.Content>Нет данных</Message.Content>
                                    }
                                </Message>
                            </Grid.Column>
                        </Grid.Row>
                    }
                </>
            )}
        </Grid>
    </InformationPanelItem>
});

const TemplateButtons = observer((props: { template: TemplateStore }) => {
    const {template} = props;
    return <Grid textAlign={'right'}>
        <Grid.Row>
            <Grid.Column>
                <Button icon onClick={() => template.switchHistoryVisible()}>
                    <Icon name={template.historyIsVisible ? 'hide' : 'history'}/>
                </Button>
                {!template.settingsVisible &&
                    <Button icon onClick={() => template.showSettings()}>
                        <Icon name='setting'/>
                    </Button>
                }
                <Button disabled={!template.canSave} color={'teal'}
                        loading={template.isSaving}
                        onClick={() => void template.save()}>Сохранить</Button>
            </Grid.Column>
        </Grid.Row>
    </Grid>
})

const TemplateSettings = observer((props: { template: TemplateStore }) => {
    const {template} = props;
    if (!template.settingsVisible) {
        return <></>
    }

    return <InformationPanelItem header={'Дополнительные настройки'}>
        <Grid>
            <TextField name={'Список ссылок на изображения'}
                       tip={'Список (через запятую) прямых ссылок на изображния (для тех, кто не хранит изображения в МС)'}
                       value={template.imageUrls}
                       onContextMenu={(e: any) => openContextMenu(e, value => template.changeImageUrls(value))}
                       onChange={value => template.changeImageUrls(value)}>
                <input/>
                <FieldSelector onSelect={value => template.changeImageUrls((template.imageUrls ?? '') + value)}/>
            </TextField>
            <TextField name={'Порядок изображений'}
                       value={template.imageRange}
                       onContextMenu={(e: any) => openContextMenu(e, value => template.changeImageRange(value))}
                       tip={'Порядок (через запятую), в котором будут отображаться изображения в объявлении'}
                       onChange={value => template.changeImageRange(value)}>
                <input/>
                <FieldSelector onSelect={value => template.changeImageRange((template.imageRange ?? '') + value)}/>
            </TextField>
            <FieldWithCheckbox name={"Считать цену обязательной"}
                               tip={"Если цена 0 - товар выгружаться не будет"}
                               value={template.requiredPrice}
                               onChange={value => template.changeRequiredPrice(value)}
                               width={9}
            />
            <FieldWithCheckbox name={"Считать изображения обязательными"}
                               tip={"Если в товаре нет изображений - он не попадет в выгрузку"}
                               value={template.requiredImages}
                               onChange={value => template.changeRequiredImages(value)}
                               width={9}
            />
        </Grid>
    </InformationPanelItem>
})

function getPosition(e: any) {
    let posx = 0;
    let posy = 0;

    if (e.pageX || e.pageY) {
        posx = e.pageX;
        posy = e.pageY;
    } else if (e.clientX || e.clientY) {
        posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }

    return {
        x: posx,
        y: posy
    }
}

function openContextMenu(e: any, onSelect: (value: string) => void) {
    e.preventDefault();
    const positions = getPosition(e)
    contextMenuStore.open(positions.x, positions.y, e.target, onSelect);
}


export default observer(TemplateComponent);