import api from "../Services/Api";

class AdminService {
    async createJobs() {
        await api.post('admin/createJobs', {});
    }

    async runJobs() {
        await api.post('admin/runJobs', {});
    }

    async stopJobs() {
        await api.post('admin/stopJobs', {});
    }

    async deleteAllJobs() {
        await api.post('admin/deleteAllJobs', {});
    }
    async actualizeRequiredPriceInTemplates() {
        await api.post('admin/actualizeRequiredPriceInTemplates', {});
    }

    async actualizeUnloadActiveStores() {
        await api.post('admin/actualizeUnloadActiveStores', {});
    }
}

export default new AdminService();