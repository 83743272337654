import React, {useEffect} from "react";
import store from "../../../Stores/V2/TemplateListStore";
import {observer} from "mobx-react-lite";
import {InformationPanelItem} from "../../Dashboard/InformationPanelItem";
import {Button, Input, List} from "semantic-ui-react";
import TemplateV2Raw from "./TemplateV2Raw";
import createTemplateV2Store from "../../../Stores/V2/CreateTemplateV2Store";
import templateListStore from "../../../Stores/TemplateListStore";

function TemplateV2List() {
    useEffect(() => {
        void store.load()
        return function cleanup() {
            store.cleanup();
        }
    }, []);

    return <InformationPanelItem header={'Шаблоны'}
                                 listPlaceholder
                                 hideContentWhenLoading
                                 loading={store.isLoading && !store.isLoaded}>
        {store.templates.length > 0 &&
            <>
                <Input value={store.searchText}
                       onChange={(e) => store.changeSearchText(e.target.value)}
                       fluid
                       disabled={createTemplateV2Store.visible}
                       icon='search'
                       size={'small'} placeholder='Поиск...'/>
                <List selection>
                    {store.templates
                        .filter(x => store.isFitsFilter(x))
                        .map(x =>
                            <TemplateV2Raw key={x.id} template={x} disabled={createTemplateV2Store.visible}/>)}
                </List>
            </>
        }
        <Button color={'teal'} 
                fluid
                disabled={createTemplateV2Store.visible}
                onClick={() => createTemplateV2Store.show()}>Создать шаблон</Button>
    </InformationPanelItem>
}

export default observer(TemplateV2List);