import React from "react";
import {observer} from "mobx-react-lite";
import {Form} from "semantic-ui-react";
import EditorInput from "../../Pages/Shared/EditorInput";
import CategoryCreatorStore from "./CategoryCreatorStore";

function CategoryCreator(props: {store: CategoryCreatorStore}) {
    const {store} = props;
    return <>
        {store.creationStarted
            ?
            <Form.Field>
                <label>Имя новой подкатегории</label>
                <EditorInput value={store.name}
                             onEdit={value => store.updateName(value)}
                             cancel={() => store.stopCreation()}
                             save={() => void store.create()}
                             canSave={store.name !== undefined}
                             isSaving={store.isCreating}
                             ignoreOnBlur
                />
            </Form.Field>
            :
            <Form.Button onClick={() => store.startCreation()} basic>Создать
                подкатегорию</Form.Button>
        }
    </>
}

export default observer(CategoryCreator);