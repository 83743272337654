import React from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "semantic-ui-react";
import {Route, Switch} from "react-router-dom";
import TemplateV2List from "./TemplateV2List";
import CreateTemplateV2 from "./CreateTemplateV2";
import createTemplateV2Store from "../../../Stores/V2/CreateTemplateV2Store";
import TemplatePage from "./TemplatePage";
import DeleteTemplateButton from "./DeleteTemplateButton";

function TemplatesV2Page() {
    return <Grid>
        <DeleteTemplateButton/>
        <Grid.Column width={3}>
            <TemplateV2List/>
        </Grid.Column>
        <Grid.Column width={10}>
            {createTemplateV2Store.visible
                ? <CreateTemplateV2/>
                : <Switch>
                    <Route path={`/templatesV2/:id`}>
                        <TemplatePage/>
                    </Route>
                </Switch>
            }
        </Grid.Column>
    </Grid>
}

export default observer(TemplatesV2Page);