import {observable, runInAction} from "mobx";
import api from "../Services/Api";
import productFolderListStore from "./ProductFolderListStore";

interface ITemplateByFoldersStore {
    folders: string[];
    templateId: string;
    save: () => void;
    canSave: boolean;
    trySave: boolean;
}

const templateByFoldersStore = observable<ITemplateByFoldersStore>({
    trySave: false,
    folders: [],
    templateId: '',
    async save() {
        if (!templateByFoldersStore.trySave) {
            templateByFoldersStore.trySave = true;
        }

        if (!templateByFoldersStore.canSave) {
            return;
        }

        templateByFoldersStore.trySave = false;

        const result = await api.post('productFolder/save', {
            productFolders: templateByFoldersStore.folders,
            templateId: templateByFoldersStore.templateId
        });
        if (result.success) {
            runInAction(() => {
                void productFolderListStore.load();
                templateByFoldersStore.folders = [];
                templateByFoldersStore.templateId = '';
            })
        }
    },
    get canSave() {
        if (!templateByFoldersStore.folders) {
            return false;
        }
        
        if (templateByFoldersStore.folders.length === 0) {
            return false;
        }
        
        if (!templateByFoldersStore.templateId) {
            return false;
        }

        return true;
    }
});

export default templateByFoldersStore;