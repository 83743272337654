import React from "react";
import {
    Button,
    Dropdown, DropdownDivider,
    DropdownHeader,
    DropdownItem,
    DropdownMenu,
    Menu
} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import AddUserButton from "../AddUserButton/AddUserButton";
import usersStore from "../UsersStore";
import authStore from "../../Stores/AuthStore";
import adminService from "../AdminService";
import tokenConverterStore from "../TokenConverterStore";
import analyticsStore from "../AnalyticsStore";
import stockWebhookTesterStore from "../EventTesterStore";
import {NavLink} from "react-router-dom";
import {routeName} from "../AdminRouter";
import subscriptionCheckerStore from "../SubscriptionChecker/SubscriptionCheckerStore";

function AdminMenu() {
    return (
        <>
            <Menu>
                <Dropdown item icon='wrench'>
                    <DropdownMenu>
                        <DropdownHeader>Выгрузки</DropdownHeader>
                        <DropdownItem onClick={() => adminService.createJobs()}>Создать задачи</DropdownItem>
                        <DropdownItem onClick={() => adminService.runJobs()}>Запустить</DropdownItem>
                        <DropdownItem onClick={() => adminService.stopJobs()}>Остановить</DropdownItem>
                        <DropdownItem onClick={() => adminService.deleteAllJobs()}>Удалить все задачи</DropdownItem>
                        <DropdownDivider/>
                        <DropdownHeader>Разное</DropdownHeader>
                        <DropdownItem onClick={() => analyticsStore.switch()}>Аналитика</DropdownItem>
                        <DropdownItem onClick={() => tokenConverterStore.show()}>Конвертер токенов</DropdownItem>
                        <DropdownItem onClick={() => stockWebhookTesterStore.show()}>Тестрование webhook</DropdownItem>
                        <DropdownItem onClick={() => adminService.actualizeRequiredPriceInTemplates()}>Актуализировать
                            обязательность цены в шаблонах</DropdownItem>
                        <DropdownItem onClick={() => adminService.actualizeUnloadActiveStores()}>Актуализировать
                            активные склады выгрузок</DropdownItem>
                        <DropdownItem onClick={() => subscriptionCheckerStore.show()}>Проверка подписок</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <Menu.Item
                    as={NavLink}
                    to={'/' + routeName + '/users'}
                    icon='user'
                    content='Пользователи'
                />
                <Menu.Item
                    as={NavLink}
                    to={'/' + routeName + '/templatesV2'}
                    icon='file alternate'
                    content='Шаблоны V2'
                />
                <Menu.Item>
                    <a href={"/ninjaPortal/jobs"} target="_blank">Hangfire</a>
                </Menu.Item>
                <Menu.Item>
                    <a href={"https://zabbix.unitxml.ru"} target="_blank">Zabbix</a>
                </Menu.Item>
                <Menu.Item>
                    <Button basic compact onClick={() => usersStore.runUsersDataUpdate()} primary>Запустить обновление
                        данных</Button>
                </Menu.Item>
                <Menu.Item>
                    <AddUserButton/>
                </Menu.Item>
                <Menu.Item position={'right'}>
                    <Button basic color={'red'} onClick={() => authStore.logOut()}>Выход</Button>
                </Menu.Item>
            </Menu>
        </>
    )
}

export default observer(AdminMenu);
