import {Grid} from "semantic-ui-react";
import React from "react";
import {observer} from "mobx-react-lite";
import FieldName from "./FieldName";
import FieldValue from "./FieldValue";
import IHasIdAndName from "../../Entities/IHasIdAndName";
import FieldIdSelector from "./FieldIdSelector";

function FieldWithFieldIdSelector(props: {
    name: string,
    value?: string,
    fields: IHasIdAndName[],
    onChange(value?: string): void,
    onSearchChange?(value: string): void,
    notClearable?: boolean
    required?: boolean,
    tip?: string,
    placeholder?: string,
    visible?: boolean
}) {
    const {
        name, value, fields, onChange,
        notClearable, required,
        tip, placeholder,
        onSearchChange
    } = props;

    if (props.visible !== undefined) {
        if (!props.visible) {
            return <></>;
        }
    }

    return <Grid.Row>
        <FieldName name={name} required={required}/>
        <FieldValue style={tip && {display: 'flex'}}>
            <FieldIdSelector 
                value={value}
                fields={fields}
                onChange={onChange}
                onSearchChange={onSearchChange}
                notClearable={notClearable}
                tip={tip}
                placeholder={placeholder}
            />
        </FieldValue>
    </Grid.Row>
}




export default observer(FieldWithFieldIdSelector);
