import {observable} from "mobx";
import api from "../Services/Api";
import settingsStore from "./StoresSettingsStorage";
import StoreFieldValue from "../Entities/FieldValue";

export default class StoreSettingsStorage {
    id: string;
    name: string;
    @observable priceFieldId?: string;
    @observable descriptionFieldId?: string;
    @observable priority: number;
    @observable fieldValues: StoreFieldValue[];
    @observable saving: boolean = false;
    @observable ignoreLowerPriorityStores: boolean;
    @observable canUseNegativeStock: boolean;
    private hasPrivateChanges: boolean = false;

    constructor(id: string, name: string, ignoreLowerPriorityStores: boolean, canUseNegativeStock: boolean, fieldValues: StoreFieldValue[], priority: number, priceFieldId?: string, descriptionFieldId?: string) {
        this.id = id;
        this.name = name;
        this.fieldValues = fieldValues;
        this.priority = priority;
        this.priceFieldId = priceFieldId;
        this.descriptionFieldId = descriptionFieldId;
        this.ignoreLowerPriorityStores = ignoreLowerPriorityStores;
        this.canUseNegativeStock = canUseNegativeStock;
    }

    async save() {
        if (this.saving || !this.hasChanges) return;

        this.changeSaving(true);
        try {
            const result = await api.post("settings/store.saveSettings", {
                storeId: this.id,
                ignoreLowerPriorityStores: this.ignoreLowerPriorityStores,
                fieldValues: this.fieldValues
                    .filter(x => x.hasChanges)
                    .map(x => ({id: x.id, fieldId: x.fieldId, value: x.value})),
                priority: this.priority,
                priceFieldId: this.priceFieldId === '' ? null : this.priceFieldId,
                descriptionFieldId: this.descriptionFieldId === '' ? null : this.descriptionFieldId,
                canUseNegativeStock: this.canUseNegativeStock
            });
            if (result.success) {
                await settingsStore.load();
            }
        } finally {
            this.changeSaving(false);
        }
    }

    get hasChanges() {
        return this.hasPrivateChanges || this.fieldValues.some(x => x.hasChanges);
    }

    private changeSaving(value: boolean) {
        this.saving = value;
    }

    getOrCreateFieldValue(fieldId: string): StoreFieldValue {
        const existsFieldValue = this.getFieldValue(fieldId);
        if (existsFieldValue) {
            return existsFieldValue;
        }
        const fieldValue = new StoreFieldValue(fieldId);
        this.fieldValues.push(fieldValue);

        return fieldValue;
    }

    changePriceFieldId(value?: string) {
        this.priceFieldId = value;
        this.hasPrivateChanges = true;
    }

    changeDescriptionFileId(value?: string) {
        this.descriptionFieldId = value;
        this.hasPrivateChanges = true;
    }

    changePriority(value: number) {
        this.priority = value;
        this.hasPrivateChanges = true;
    }

    changeIgnoreLowerPriorityStores(value: boolean) {
        this.ignoreLowerPriorityStores = value;
        this.hasPrivateChanges = true;
    }

    changeCanUseNegativeStock(value: boolean) {
        this.canUseNegativeStock = value;
        this.hasPrivateChanges = true;
    }

    private getFieldValue(fieldId: string): StoreFieldValue {
        return this.fieldValues.filter(x => x.fieldId === fieldId)[0];
    }
}
