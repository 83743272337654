import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import templatePageStore from "../../../Stores/V2/TemplatePageStore";
import TemplateComponent from "./TemplateComponent";
import {InformationPanelItem} from "../../Dashboard/InformationPanelItem";

function TemplatePage() {
    const params = useParams<{ id: string }>();
    useEffect(() => {
        void templatePageStore.init(params.id);
        return function cleanup() {
            templatePageStore.cleanup();
        }
    }, [params.id])

    if (!templatePageStore.template || (templatePageStore.isLoading && !templatePageStore.isLoaded)) {
        return <InformationPanelItem listPlaceholder listPlaceholderLines={15} loading={true}></InformationPanelItem>
    }

    return <TemplateComponent template={templatePageStore.template}/>
}

export default observer(TemplatePage);