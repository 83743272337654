import React from "react";
import {observer} from "mobx-react-lite";
import {Form, Grid, TextArea} from "semantic-ui-react";
import store from "./SubscriptionCheckerStore";

function SubscriptionChecker() {
    if (!store.visible) {
        return <></>
    }
    
    return <Grid>
        <Grid.Column width={7}>
            <Form>
                <Form.Input label={'UserId'}
                            value={store.userId ?? ''}
                            onChange={e => store.updateUserId(e.target.value)}/>
                <Form.Group>
                    <Form.Button basic onClick={() => void store.check()}>Запросить</Form.Button>
                    <Form.Button basic onClick={() => store.hide()}>Скрыть</Form.Button>
                </Form.Group>
                <Form.Field>
                    <label>Подписка</label>
                    <TextArea rows={5}
                              value={store.subscriptionText}
                    />
                </Form.Field>
            </Form>
        </Grid.Column>
    </Grid>
}

export default observer(SubscriptionChecker);