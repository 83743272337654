import React from "react";
import {observer} from "mobx-react-lite";
import {List} from "semantic-ui-react";
import {useHistory} from "react-router-dom";
import {TemplateV2Dto} from "../../../Entities/Dtos";
import DeleteButton from "../../Shared/DeleteButton";
import templateListStore from "../../../Stores/V2/TemplateListStore";
import templatePageStore from "../../../Stores/V2/TemplatePageStore";
import store from "../../../Stores/V2/TemplateListStore";

function TemplateV2Raw(props: { template: TemplateV2Dto, disabled: boolean }) {
    const history = useHistory();
    const {template, disabled} = props;
    return <List.Item disabled={disabled} 
                      active={template.id === templatePageStore.id}
                      draggable
                      onDragStart={() => store.startSwapping(template)}
                      onDragEnter={() => store.finishSwapping(template)}
                      onDragEnd={() => store.swap()}
                      onDragOver={(e: any) => e.preventDefault()}
                      onClick={() => history.push(`/templatesV2/${template.id}`)}>
        <DeleteButton onDelete={() => templateListStore.markToDelete(template)}/>
        {template.name}
    </List.Item>
}

export default observer(TemplateV2Raw);