import React from "react";
import {Form} from "semantic-ui-react";
import FieldIdSelector from "../../Pages/Shared/FieldIdSelector";
import categoryLookupsStore from "./CategoryLookupsStore";
import CategoryEditorStore from "./CategoryEditorStore";
import {observer} from "mobx-react-lite";

function CategoryEditor(props: { category: CategoryEditorStore }) {
    const {category} = props;
    return <>
        <Form.Input readOnly id={'id'} value={category.id} label={'Id'}/>
        <Form.Input id={'name'} value={category.name}
                    onChange={e => category.changeName(e.target.value)}
                    label={'Название'}/>
        <Form.Input value={category.documentationLink}
                    onChange={e => category.changeDocumentationLink(e.target.value)}
                    label={'Ссылка на документацию'}/>
        <Form.Field>
            <label>Родительская категория</label>
            <FieldIdSelector value={category.parentId}
                             fields={categoryLookupsStore.categories}
                             onChange={value => category.changeParentId(value)}
            />
        </Form.Field>
        <Form.Checkbox checked={category.requiredImages} label={'Обязательность изображений'}
                       onChange={(_, data) => category.changeRequiredImages(data.checked ?? false)}/>
        <Form.Checkbox checked={category.requiredPrice} label={'Обязательность цены'}
                       onChange={(_, data) => category.changeRequiredPrice(data.checked ?? false)}/>
        <Form.Group widths={'equal'}>
            <Form.Button color={'teal'}
                         fluid
                         width={9}
                         loading={category.isSaving} basic
                         onClick={() => void category.save()}> Сохранить</Form.Button>
            <Form.Button loading={category.isDeleting} basic
                         fluid
                         width={5}
                         color={'red'}
                         onClick={() => void category.delete()}>Удалить</Form.Button>
        </Form.Group>
    </>
}

export default observer(CategoryEditor);