import {observable} from "mobx";
import api from "../../Services/Api";

class SubscriptionCheckerStore {
    @observable userId?: string;
    @observable subscriptionText?: string;
    @observable visible: boolean = false;

    async check() {
        if (!this.userId) {
            return;
        }

        const result = await api.get('admin/user.getSubscription?userId=' + this.userId);
        if (result.success) {
            this.subscriptionText = result.data;
        }
    }

    updateUserId(userId: string) {
        this.userId = userId;
    }

    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
    }
}

const subscriptionCheckerStore = new SubscriptionCheckerStore();
export default subscriptionCheckerStore;