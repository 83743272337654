import {observable} from "mobx";
import {CategoryV2Dto, FieldTypeDto} from "../../Entities/Dtos";
import api from "../../Services/Api";

class CategoryLookupsStore {
    @observable fieldTypes: FieldTypeDto[] = [];
    @observable categories: CategoryV2Dto[] = [];

    async load() {
        let result = await api.get('categoryV2/fieldTypes');
        if (result.success) {
            this.fieldTypes = result.data;
        }

        result = await api.get('categoryV2/list?ignoreParentCategory=true');
        if (result.success) {
            this.categories = result.data;
        }
    }

    getCategoryById(categoryId?: string) {
        if (!categoryId) {
            return undefined;
        }
        return this.categories.find((c) => c.id === categoryId);
    }
}

const categoryLookupsStore = new CategoryLookupsStore();
export default categoryLookupsStore;