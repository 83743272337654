import api from "../../Services/Api";
import {observable, runInAction} from "mobx";
import {TemplateV2Dto} from "../../Entities/Dtos";

class TemplateListStore {
    @observable templates: TemplateV2Dto[] = [];
    @observable isLoading: boolean = false;
    @observable isLoaded: boolean = false;
    @observable searchText?: string;
    @observable templateToDelete?: TemplateV2Dto;
    @observable isDeleting: boolean = false;
    @observable sourceSwapItem?: TemplateV2Dto;
    @observable destinationSwapItem?: TemplateV2Dto;

    async load() {
        this.setIsLoading(true);
        try {
            const result = await api.get('templateV2/list');
            if (result.success) {
                runInAction(() => {
                    this.templates = result.data;
                    this.isLoaded = true;
                });
            }
        } finally {
            this.setIsLoading(false);
        }
    }

    cleanup() {
        this.setIsLoading(false);
        this.templateToDelete = undefined;
        this.searchText = undefined;
    }

    private setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    private setIsDeleting(isDeleting: boolean) {
        this.isDeleting = isDeleting;
    }

    changeSearchText(searchText: string) {
        this.searchText = searchText;
    }

    isFitsFilter(template: TemplateV2Dto): boolean {
        if (!this.searchText) return true;
        return template.name.toLowerCase().includes(this.searchText);
    }

    markToDelete(template: TemplateV2Dto) {
        this.templateToDelete = template;
    }

    stopDeleting() {
        this.templateToDelete = undefined;
    }

    async delete(onSuccessDeletion: () => void) {
        if (!this.templateToDelete) {
            return;
        }

        this.setIsDeleting(true);
        try {
            const result = await api.post('templateV2/delete', {id: this.templateToDelete!.id});
            if (result.success) {
                await this.load();
                onSuccessDeletion();
            }
            this.stopDeleting();
        } finally {
            this.setIsDeleting(false);
        }
    }

    startSwapping(template: TemplateV2Dto) {
        this.sourceSwapItem = template;
    }

    finishSwapping(template: TemplateV2Dto) {
        this.destinationSwapItem = template;
    }

    swap() {
        if (!this.sourceSwapItem || !this.destinationSwapItem || this.sourceSwapItem === this.destinationSwapItem) {
            this.sourceSwapItem = undefined;
            this.destinationSwapItem = undefined;
            return;
        }
        
        const sourcePriority = this.sourceSwapItem.priority;
        this.sourceSwapItem.priority = this.destinationSwapItem.priority;
        this.destinationSwapItem.priority = sourcePriority;
        void this.changePriority(this.sourceSwapItem);
        void this.changePriority(this.destinationSwapItem);
        this.sourceSwapItem = undefined;
        this.destinationSwapItem = undefined;

        this.sort();
    }

    async changePriority(template: TemplateV2Dto){
        await api.post("template/changePriority", {
            id: template.id,
            priority: template.priority
        });
    }

    sort() {
        runInAction(() => {
            this.templates = this.templates.slice().sort((a, b) => a.priority - b.priority);
        });
    }
}

const templateListStore = new TemplateListStore();
export default templateListStore;