import api from "../Services/Api";
import {observable} from "mobx";
import {GetStoresResponse, StoreSettingsDto} from "../Entities/Dtos";
import StoreField from "../Entities/StoreField";
import StoreFieldValue from "../Entities/FieldValue";
import StoreSettingsStorage from "./StoreSettingsStorage";
import skladStore from "./SkladStore";

class StoresSettingsStorage {
    @observable stores: StoreSettingsStorage[] = [];
    @observable fields: StoreField[] = [];
    @observable currentStore?: StoreSettingsStorage;
    @observable loading: boolean = false;
    @observable isLoaded: boolean = false;

    async load() {
        this.changeLoading(true);
        if (!skladStore.isLoaded) {
            await skladStore.load();
        }

        try {
            const result = await api.get("settings/stores.getSettings");
            if (result.success) {
                const response = result.data as GetStoresResponse;

                this.fields = response.fields.map(f => new StoreField(f.id, f.name));

                this.stores = response.stores.map(mapStoreSettings);
                if (this.currentStore) {
                    this.currentStore = this.stores.filter(x => x.id === this.currentStore?.id)[0];
                } else {
                    this.currentStore = this.stores[0];
                }

                this.isLoaded = true;
            }
        } finally {
            this.changeLoading(false);
        }
    }

    selectStore(store: StoreSettingsStorage) {
        this.currentStore = store;
    }

    private changeLoading(value: boolean) {
        this.loading = value;
    }
}

function mapStoreSettings(s: StoreSettingsDto): StoreSettingsStorage {
    const fieldValues = s.fieldValues.map(fv => new StoreFieldValue(fv.fieldId, fv.id, fv.value));
    return new StoreSettingsStorage(s.id, s.name, s.ignoreLowerPriorityStores, s.canUseNegativeStock, fieldValues, s.priority, s.priceFieldId, s.descriptionFieldId)
}

const storesSettingsStorage = new StoresSettingsStorage();
export default storesSettingsStorage;
