import {observable} from "mobx";
import CategoryStore from "./CategoryStore";
import api from "../../Services/Api";
import templateListStore from "./TemplateListStore";

class CreateTemplateV2Store {
    @observable name: string = '';
    @observable category?: CategoryStore;
    @observable visible: boolean = false;
    @observable isCreating: boolean = false;

    canCreate(category?: CategoryStore): boolean {
        if (!this.name) {
            return false;
        }

        if (!category) {
            return false;
        }

        return category.hasFields;
    }
    
    cleanup() {
        this.name = '';
        this.category = undefined;
        this.visible = false;
    }

    changeName(name: string) {
        this.name = name;
    }

    async create(category?: CategoryStore) {
        if (!this.canCreate(category)) {
            return;
        }
        this.setIsCreating(true);
        try {
            const result = await api.post('templateV2/create', {
                name: this.name,
                categoryId: category!.id,
            })

            if (result.success) {
                this.hide()
                void templateListStore.load()
            }
        }
        finally {
            this.setIsCreating(false);
        }
    }

    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
    }
    
    private setIsCreating(value: boolean) {
        this.isCreating = value;
    }
}

const createTemplateV2Store = new CreateTemplateV2Store();
export default createTemplateV2Store;