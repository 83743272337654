import {CategoryV2Dto} from "../../Entities/Dtos";
import {observable} from "mobx";
import api from "../../Services/Api";

export default class CategoryStore {
    @observable id: string;
    @observable name: string;
    @observable requiredImages: boolean;
    @observable requiredPrice: boolean;
    @observable parentId?: string;
    @observable isLoading: boolean = false;
    @observable children: CategoryStore[] = [];
    @observable selectedChild?: CategoryStore;
    @observable documentationLink: string;
    hasFields: boolean;

    constructor(id: string, name: string, requiredImages: boolean,
                requiredPrice: boolean, hasFields: boolean, 
                documentationLink: string,
                parentId?: string) {
        this.id = id;
        this.name = name;
        this.requiredImages = requiredImages;
        this.requiredPrice = requiredPrice;
        this.hasFields = hasFields;
        this.parentId = parentId;
        this.documentationLink = documentationLink;
    }

    async loadChildren() {
        this.setIsLoading(true);
        try {
            const result = await api.get('categoryV2/list?parentCategoryId=' + this.id);
            if (result.success) {
                const categoryDtos = result.data as CategoryV2Dto[];
                const categories = categoryDtos
                    .map(c => new CategoryStore(c.id, c.name, c.requiredImages, c.requiredPrice, c.hasFields, c.documentationLink, c.parentId));
                this.setChildren(categories);
            }
        } finally {
            this.setIsLoading(false);
        }
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    setChildren(children: CategoryStore[]) {
        this.children = children;
    }

    clearSelectedChild() {
        if (!this.selectedChild) {
            return;
        }

        this.selectedChild.clearSelectedChild();
        this.selectedChild = undefined;
    }

    selectChild(categoryId: string) {
        if (this.selectedChild) {
            this.selectedChild.clearSelectedChild();
        }

        this.selectedChild = this.children.find(c => c.id === categoryId);
        if (!this.selectedChild) {
            return;
        }

        void this.selectedChild.loadChildren()
    }
}
