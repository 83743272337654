import CategoryEditorStore from "./CategoryEditorStore";
import {CategoryV2Dto} from "../../Entities/Dtos";
import {observable} from "mobx";
import categoryLookupsStore from "./CategoryLookupsStore";
import CategoryCreatorStore from "./CategoryCreatorStore";

class CategoryEditorPageStore {
    @observable category?: CategoryV2Dto;
    @observable editor?: CategoryEditorStore;
    @observable creator: CategoryCreatorStore;

    constructor() {
        this.creator = new CategoryCreatorStore(() => {
            void categoryLookupsStore.load();
        })
    }

    init(category?: CategoryV2Dto, onSaved?: () => void, onDeleted?: () => void) {
        if (!category) {
            this.cleanup();
            return;
        }

        this.category = category;
        this.editor = new CategoryEditorStore(category.id, category.name,
            category.requiredImages, category.requiredPrice,
            category.documentationLink,
            category.parentId, () => {
                void categoryLookupsStore.load();
                if (onSaved) {
                    onSaved();
                }
            }, () => {
                this.cleanup();
                void categoryLookupsStore.load();
                if (onDeleted) {
                    onDeleted();
                }
            });
        this.creator.setParentId(category.id);
    }

    cleanup() {
        this.editor = undefined;
        this.category = undefined;
        this.creator.setParentId(undefined);
    }
}

const categoryEditorPageStore = new CategoryEditorPageStore();
export default categoryEditorPageStore;