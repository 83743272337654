import React, { useEffect, useState } from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import api from '../Services/Api';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function LoginForm() {
    const query = useQuery();
    const contextKey = query.get("contextKey");
    const history = useHistory();
    const [message, setMessage] = useState<string>("");
    useEffect(() => {
        async function auth() {
            if (contextKey) {
                const isSuccess = await api.auth(contextKey);
                if (isSuccess) {
                    history.push("/");
                }
                else {
                    setMessage("Ошибка :( Обратитесь к системному администратору.")
                }
            }
            else {
                setMessage("Пустой contextId. Пожалуйста, обновите страницу.")
            }
        }
        void auth();
    }, [contextKey])
    return (
        <>
            {message}
        </>
    )
}

export default LoginForm;