import api from "../../Services/Api";
import {computed, observable} from "mobx";
import {CategoryFieldDto} from "../../Entities/Dtos";
import CategoryFieldStore from "./CategoryFieldStore";

export default class CategoryEditorStore {
    @observable isSaving: boolean = false;
    @observable isDeleting: boolean = false;
    id: string;
    @observable name: string;
    @observable requiredImages: boolean;
    @observable requiredPrice: boolean;
    @observable parentId?: string;
    onSaved?: () => void;
    onDeleted?: () => void;
    @observable fields: CategoryFieldStore[] = [];
    @observable fieldsIsLoading: boolean = false;
    @observable documentationLink: string;

    constructor(id: string, name: string, requiredImages: boolean,
                requiredPrice: boolean, documentationLink: string,
                parentId?: string,
                onSaved?: () => void, onDeleted?: () => void) {
        this.id = id;
        this.name = name;
        this.requiredImages = requiredImages;
        this.requiredPrice = requiredPrice;
        this.documentationLink = documentationLink;
        this.parentId = parentId;
        this.onSaved = onSaved;
        this.onDeleted = onDeleted;
        void this.loadFields();
    }

    async save() {
        this.setIsSaving(true);
        try {
            const result = await api.post('categoryV2/save', {
                id: this.id,
                name: this.name,
                parentCategoryId: this.parentId ? this.parentId : undefined,
                requiredImages: this.requiredImages,
                requiredPrice: this.requiredPrice,
                documentationLink: this.documentationLink
            });

            if (result.success) {
                if (this.onSaved) {
                    this.onSaved();
                }
            }
        } finally {
            this.setIsSaving(false);
        }
    }

    @computed get hasDefaultFields(): boolean {
        for (const field of defaultFields) {
            if (this.fields.find(f => f.tag === field.tag)) {
                return true;
            }
        }

        return false;
    }


    async delete() {
        this.setIsDeleting(true);
        try {
            const result = await api.post('categoryV2/delete', {
                id: this.id
            });

            if (result.success) {
                if (this.onDeleted) {
                    this.onDeleted();
                }
            }
        } finally {
            this.setIsDeleting(false);
        }
    }

    async loadFields() {
        this.setFieldsIsLoading(true);
        try {
            const result = await api.get('categoryV2/' + this.id + '/fields');
            if (result.success) {
                const fields = result.data as CategoryFieldDto[];
                this.fields = fields.map(f => new CategoryFieldStore(f.id, f.categoryId, f.name,
                    f.tag, f.order,
                    f.helpUrl, f.isRequired,
                    f.type, f.tip,
                    f.defaultValue, f.possibleValues, f.multiplyTag, () => {
                        this.loadFields();
                    }));
            }
        } finally {
            this.setFieldsIsLoading(false);
        }
    }

    async createField() {
        try {
            await api.post('categoryV2/createField', {
                categoryId: this.id,
                tag: '',
                name: '',
                helpUrl: '',
                isRequired: false,
                type: 'String',
                order: 100
            })
        } finally {
            await this.loadFields();
        }
    }

    async createDefaultFields() {
        try {
            for (const field of defaultFields) {
                await api.post('categoryV2/createField', {
                    categoryId: this.id,
                    tag: field.tag,
                    name: field.name,
                    helpUrl: field.helpUrl,
                    isRequired: field.isRequired,
                    type: field.type,
                    order: field.order
                });
            }
        } finally {
            await this.loadFields();
        }
    }

    changeName(name: string) {
        this.name = name;
    }

    changeParentId(id?: string) {
        this.parentId = id;
    }

    changeRequiredImages(value: boolean) {
        this.requiredImages = value;
    }

    changeRequiredPrice(value: boolean) {
        this.requiredPrice = value;
    }

    changeDocumentationLink(value: string) {
        this.documentationLink = value;
    }

    setIsSaving(isSaving: boolean) {
        this.isSaving = isSaving;
    }

    setIsDeleting(isDeleting: boolean) {
        this.isDeleting = isDeleting;
    }

    setFieldsIsLoading(value: boolean) {
        this.fieldsIsLoading = value;
    }
}

const defaultFields = [
    {
        tag: 'Id',
        name: 'Id',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/98189?fileFormat=xml#field-Id',
        isRequired: false,
        type: 'String',
        order: 0
    },
    {
        tag: 'Title',
        name: 'Наименование',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/98189?fileFormat=xml#field-Title',
        isRequired: true,
        type: 'String',
        order: 1
    },
    {
        tag: 'Address',
        name: 'Адрес',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/98189?fileFormat=xml#field-Address',
        isRequired: false,
        type: 'String',
        order: 2
    },
    {
        tag: 'Stock',
        name: 'Остаток',
        helpUrl: 'https://support.avito.ru/articles/2678',
        isRequired: false,
        type: 'String',
        order: 1000
    },
    {
        tag: 'Description',
        name: 'Описание',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/98189?fileFormat=xml#field-Description',
        isRequired: true,
        type: 'Text',
        order: 1001
    },
];