import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Button, Form, Grid} from "semantic-ui-react";
import CategoryV2Selector from "./CategoryV2SelectorTree";
import FormLabel from "../../Shared/FormLabel";
import createTemplateV2Store from "../../../Stores/V2/CreateTemplateV2Store";
import categoriesStore from "../../../Stores/V2/CategoriesStore";
import {InformationPanelItem} from "../../Dashboard/InformationPanelItem";

function CreateTemplateV2() {
    useEffect(() => {
        return function cleanup() {
            createTemplateV2Store.cleanup()
        }
    }, []);
    return <>
        <Grid>
            <Grid.Column width={6}>
                <FormLabel text={'Категория'}/>
                <CategoryV2Selector/>
            </Grid.Column>
            <Grid.Column width={5}>
                <Form onSubmit={() => createTemplateV2Store.create(categoriesStore.lastSelectedCategory)}>
                    <Form.Input label={'Название шаблона'}
                                onChange={(e) => createTemplateV2Store.changeName(e.target.value ?? '')}
                                value={createTemplateV2Store.name}/>
                    <Form.Button fluid
                                 type="submit"
                                 color={'teal'}
                                 loading={createTemplateV2Store.isCreating}
                                 disabled={!createTemplateV2Store.canCreate(categoriesStore.lastSelectedCategory)}>Создать</Form.Button>
                    <Form.Button fluid basic onClick={() => createTemplateV2Store.hide()}>Отмена</Form.Button>
                </Form>
            </Grid.Column>
        </Grid>
    </>
}

export default observer(CreateTemplateV2);