import {observable} from "mobx";

class ContextMenuStore {
    @observable x: number = 0;
    @observable y: number = 0;
    @observable visible: boolean = false;
    activeInput?: HTMLInputElement;
    @observable customFieldsSearch?: string;
    onSelect?: (value: string) => void

    open(x: number, y: number, input: HTMLInputElement, onSelect?: (value: string) => void) {
        this.x = x;
        this.y = y;
        this.activeInput = input;
        this.show();
        this.onSelect = onSelect;
    }

    insertTextAtCaret(text: string) {
        if (this.activeInput) {
            const input = this.activeInput;
            const start = input.selectionStart ?? 0; // Позиция начала выделения
            const end = input.selectionEnd ?? 0;     // Позиция конца выделения
            const currentValue = input.value;

            // Вставляем текст в позиции каретки
            input.value = currentValue.slice(0, start) + text + currentValue.slice(end);
            if (this.onSelect) {
                this.onSelect(input.value);
            }

            // Устанавливаем новую позицию каретки
            const newCaretPosition = start + text.length;
            input.setSelectionRange(newCaretPosition, newCaretPosition);
            input.focus();

            const event = new Event("input", {bubbles: true});
            input.dispatchEvent(event);
            this.hide();
        }
    }

    async copyToClipboard() {
        if (this.activeInput) {
            const text = this.activeInput.value;
            await navigator.clipboard.writeText(text);
            this.hide();
        }
    }

    async pasteFromClipboard() {
        if (this.activeInput) {
            const text = await navigator.clipboard.readText();
            this.insertTextAtCaret(text);
        }
    }

    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
        this.customFieldsSearch = undefined;
    }

    changeCustomFieldsSearch(value: string) {
        this.customFieldsSearch = value;
    }
}

const contextMenuStore = new ContextMenuStore();

export default contextMenuStore;