import {observable} from "mobx";
import api from "../../Services/Api";
import {TemplateFieldV2HistoryItemDto} from "../../Entities/Dtos";

class TemplateFieldStore {
    id: string;
    tag: string;
    name: string;
    isRequired: boolean;
    helpUrl: string;
    tip: string;
    possibleValues?: string[];
    type: string;
    isText: boolean;
    isMultiSelectString: boolean;
    @observable value: string;
    @observable hasChanges: boolean = false;
    @observable historyIsVisible: boolean = false;
    @observable historyIsLoading: boolean = false;
    @observable history: TemplateFieldV2HistoryItemDto[] = [];


    constructor(id: string, tag: string, 
                name: string, type: string, tip: string,
                value: string, isRequired: boolean, helpUrl: string, 
                possibleValues?: string[]) {
        this.id = id;
        this.tag = tag;
        this.name = name;
        this.value = value;
        this.isRequired = isRequired;
        this.helpUrl = helpUrl;
        this.possibleValues = possibleValues;
        this.type = type;
        this.tip = tip;
        this.isText = type === "Text";
        this.isMultiSelectString = type === "MultiSelectString";
    }

    async loadHistory() {
        this.changeHistoryIsLoading(true);
        const result = await api.get("templateV2/field/" + this.id + "/history");
        if (result.success) {
            this.history = result.data as TemplateFieldV2HistoryItemDto[];
        }
        this.changeHistoryIsLoading(false);
        this.showHistory();
    }

    changeValue(value: string) {
        this.value = value;
        this.hasChanges = true;
    }

    selectPossibleValue(possibleValue: string) {
        if (this.isMultiSelectString && this.value) {
            this.value += "," + possibleValue;
        } else {
            this.value = possibleValue;
        }

        this.hasChanges = true;
    }

    selectValue(value: string) {
        if (this.value) {
            this.value += value;
        }
        else {
            this.value = value;
        }
        
        this.hasChanges = true;
    }

    showHistory() {
        this.historyIsVisible = true;
    }

    hideHistory() {
        this.historyIsVisible = false;
    }

    changeHistoryIsLoading(value: boolean) {
        this.historyIsLoading = value;
    }
}

export default TemplateFieldStore;