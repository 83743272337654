import React from "react";
import {useHistory} from "react-router-dom";
import {observer} from "mobx-react-lite";
import templateListStore from "../../../Stores/V2/TemplateListStore";
import templatePageStore from "../../../Stores/V2/TemplatePageStore";
import ConfirmDelete from "../../Shared/ConfirmDelete";
function DeleteTemplateButton() {
    const history = useHistory();
    function onSuccessDeletion() {
        console.log(templateListStore.templateToDelete?.id)
        console.log(templatePageStore.id)
        if (templateListStore.templateToDelete?.id === templatePageStore.id) {
            history.replace('/templatesV2');
        }
    }
    
    return <ConfirmDelete isOpen={templateListStore.templateToDelete !== undefined}
                          name={templateListStore.templateToDelete?.name}
                          isLoading={templateListStore.isDeleting}
                          onConfirm={() => void templateListStore.delete(() => onSuccessDeletion())}
                          onCancel={() => templateListStore.stopDeleting()}
    />
}

export default observer(DeleteTemplateButton);