import CategoryStore from "./CategoryStore";
import {CategoryV2Dto} from "../../Entities/Dtos";
import api from "../../Services/Api";
import {computed, observable} from "mobx";

class CategoriesStore {
    @observable categories: CategoryStore[] = [];
    @observable isLoading: boolean = false;
    @observable selectedCategory?: CategoryStore;

    async load() {
        this.setIsLoading(true);
        try {
            const result = await api.get('categoryV2/list');
            if (result.success) {
                const categoryDtos = result.data as CategoryV2Dto[];
                const categories = categoryDtos
                    .map(c => new CategoryStore(c.id, c.name, c.requiredImages, c.requiredPrice, c.hasFields, c.documentationLink, c.parentId));
                this.setCategories(categories);
            }
        } finally {
            this.setIsLoading(false);
        }
    }
    
    async recalculatePaths() {
        await api.post('categoryV2/recalculatePaths', {});
    }

    @computed get lastSelectedCategory(): CategoryStore | undefined {
        if (!this.selectedCategory) {
            return undefined;
        }
        let category = this.selectedCategory;
        while (category.selectedChild) {
            category = category.selectedChild;
        }

        return category;
    }

    cleanup() {
        this.categories = [];
        this.isLoading = false;
        this.clearSelectedCategory();
    }
    
    async reload() {
        this.cleanup();
        await this.load();
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    setCategories(categories: CategoryStore[]) {
        this.categories = categories;
    }

    private clearSelectedCategory() {
        if (!this.selectedCategory) {
            return;
        }

        this.selectedCategory.clearSelectedChild();
        this.selectedCategory = undefined;
    }

    select(categoryId: string) {
        if (this.selectedCategory) {
            this.selectedCategory.clearSelectedChild();
        }

        this.selectedCategory = this.categories.find(c => c.id === categoryId);
        if (!this.selectedCategory) {
            return;
        }

        void this.selectedCategory.loadChildren()
    }
}

const categoriesStore = new CategoriesStore();
export default categoriesStore;