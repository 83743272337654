import {computed, observable} from "mobx";
import api from "../../Services/Api";

export default class CategoryFieldStore {
    id: string;
    categoryId: string;
    @observable name: string;
    @observable tag: string;
    @observable order: number;
    @observable helpUrl: string;
    @observable isRequired: boolean;
    @observable type: string;
    @observable tip?: string;
    @observable defaultValue?: string;
    @observable multiplyTag?: string;
    @observable possibleValues?: string;
    @observable error?: string;
    @observable isSaving: boolean = false;
    onSaved?: () => void

    constructor(id: string,
                categoryId: string,
                name: string,
                tag: string,
                order: number,
                helpUrl: string,
                isRequired: boolean,
                type: string,
                tip?: string,
                defaultValue?: string,
                possibleValues?: string,
                multiplyTag?: string,
                onSaved?: () => void) {
        this.id = id;
        this.categoryId = categoryId;
        this.name = name;
        this.tag = tag;
        this.order = order;
        this.helpUrl = helpUrl;
        this.isRequired = isRequired;
        this.type = type;
        this.tip = tip;
        this.defaultValue = defaultValue;
        this.multiplyTag = multiplyTag;
        this.possibleValues = possibleValues;
        this.onSaved = onSaved;
    }

    async save() {
        if (!this.canSave) {
            return;
        }
        
        this.setIsSaving(true);
        try {
            const request = {
                id: this.id,
                name: this.name,
                categoryId: this.categoryId,
                tag: this.tag,
                helpUrl: this.helpUrl,
                isRequired: this.isRequired,
                type: this.type,
                tip: this.tip,
                defaultValue: this.defaultValue,
                multiplyTag: this.multiplyTag,
                possibleValues: this.possibleValues,
                order: this.order
            };
            const result = await api.post('categoryV2/editField', request);
            if (result.success) {
                if (this.onSaved) {
                    this.onSaved();
                }
            } else {
                this.setError(result.error);
            }
        } finally {
            this.setIsSaving(false);
        }
    }

    @computed get canSave(): boolean {
        if (this.name && this.tag && this.type && this.helpUrl) {
            return true;
        }
        return false;
    }

    async delete() {
        const result = await api.post('categoryV2/deleteField', {
            id: this.id,
            categoryId: this.categoryId
        });
        if (result.success) {
            if (this.onSaved) {
                this.onSaved();
            }
        } else {
            this.setError(result.error);
        }
    }

    private setError(value: string) {
        this.error = value;
    }

    private setIsSaving(value: boolean) {
        this.isSaving = value;
    }

    updateName(value: string) {
        this.name = value;
    }

    updateTag(value: string) {
        this.tag = value;
    }

    updateHelpUrl(value: string) {
        this.helpUrl = value;
    }

    updateIsRequired(value: boolean) {
        this.isRequired = value;
    }

    updateType(value: string) {
        this.type = value;
    }

    updateTip(value: string) {
        this.tip = value;
    }

    updateDefaultValue(value: string) {
        this.defaultValue = value;
    }

    updateMultiplyTag(value: string) {
        this.multiplyTag = value;
    }

    updatePossibleValues(value?: string) {
        this.possibleValues = value;
    }

    updateOrder(order: number) {
        this.order = order;
    }
}