import React from "react";
import {Dropdown} from "semantic-ui-react";
import fieldsLookupStorage from "../../../Stores/FieldsLookupStorage";
import {observer} from "mobx-react-lite";

function FieldSelector(props: { isText?: boolean, onSelect: (value: string) => void }) {
    const {isText, onSelect} = props;
    return <>
        <Dropdown
            icon='angle down'
            button
            className={isText ? 'icon fix-height' : 'icon'}
            direction='right'
            style={{marginRight: '1px', color: '#2185d0'}}
        >
            <Dropdown.Menu>
                {fieldsLookupStorage.baseFields && fieldsLookupStorage.baseFields.length > 0 &&
                    <>
                        <Dropdown.Header icon={'angle double down'} content='Поля из МС'/>
                        <Dropdown.Menu scrolling style={{maxHeight: '150px'}}>
                            {fieldsLookupStorage.baseFields.map(f => (
                                <Dropdown.Item key={f.id}
                                               text={f.name}
                                               label={{color: 'green', empty: true, circular: true}}
                                               onClick={() => onSelect(`[!${f.name}!]`)}/>
                            ))}
                        </Dropdown.Menu>
                    </>
                }
                {fieldsLookupStorage.prices && fieldsLookupStorage.prices.length > 0 &&
                    <>
                        <Dropdown.Header icon={'angle double down'} content='Цены продажи'/>
                        <Dropdown.Menu scrolling style={{maxHeight: '150px'}}>
                            {fieldsLookupStorage.prices.map(f => (
                                <Dropdown.Item key={f.id}
                                               text={f.name}
                                               label={{color: 'blue', empty: true, circular: true}}
                                               onClick={() => onSelect(`[!${f.name}!]`)}/>
                            ))}
                        </Dropdown.Menu>
                    </>
                }
            </Dropdown.Menu>
        </Dropdown>

        <Dropdown
            icon='angle down'
            button
            className={isText ? 'icon fix-height' : 'icon'}
            direction='right'
            style={{marginRight: '1px', color: '#db2828'}}
        >
            <Dropdown.Menu>
                {fieldsLookupStorage.customFields && fieldsLookupStorage.customFields.length > 0 &&
                    <>
                        <Dropdown.Header icon={'angle double down'} content='Дополнительные поля МС'/>
                        <Dropdown.Menu scrolling style={{maxHeight: '150px'}}>
                            {fieldsLookupStorage.customFields.map(f => (
                                <Dropdown.Item key={f.id}
                                               style={{textWrap: 'balance'}}
                                               text={f.name}
                                               label={{color: 'red', empty: true, circular: true}}
                                               onClick={() => onSelect(`[$${f.name}$]`)}/>
                            ))}
                        </Dropdown.Menu>
                    </>
                }
                {fieldsLookupStorage.characteristics && fieldsLookupStorage.characteristics.length > 0 &&
                    <>
                        <Dropdown.Header icon={'angle double down'} content='Характеристики модификаций'/>
                        <Dropdown.Menu scrolling style={{maxHeight: '150px'}}>
                            {fieldsLookupStorage.characteristics.map(f => (
                                <Dropdown.Item key={f.id}
                                               text={f.name}
                                               label={{color: 'blue', empty: true, circular: true}}
                                               onClick={() => onSelect(`[$${f.name}$]`)}/>
                            ))}
                        </Dropdown.Menu>
                    </>
                }
            </Dropdown.Menu>
        </Dropdown>

        <Dropdown
            icon='angle down'
            button
            className={isText ? 'icon fix-height' : 'icon'}
            direction='right'
            style={{marginRight: '1px', color: '#098747'}}
        >
            <Dropdown.Menu>
                {fieldsLookupStorage.unloadFields && fieldsLookupStorage.unloadFields.length > 0 &&
                    <>
                        <Dropdown.Header icon={'angle double down'} content='Поля файлов'/>
                        <Dropdown.Menu scrolling style={{maxHeight: '150px'}}>
                            {fieldsLookupStorage.unloadFields.map(f => (
                                <Dropdown.Item key={f.id}
                                               text={f.name}
                                               label={{color: 'orange', empty: true, circular: true}}
                                               onClick={() => onSelect(`[%${f.name}%]`)}/>
                            ))}
                        </Dropdown.Menu>
                    </>
                }
                {fieldsLookupStorage.storeFields && fieldsLookupStorage.storeFields.length > 0 &&
                    <>
                        <Dropdown.Header icon={'angle double down'} content='Поля складов'/>
                        <Dropdown.Menu scrolling style={{maxHeight: '150px'}}>
                            {fieldsLookupStorage.storeFields.map(f => (
                                <Dropdown.Item key={f.id}
                                               text={f.name}
                                               label={{color: 'purple', empty: true, circular: true}}
                                               onClick={() => onSelect(`[!${f.name}!]`)}/>
                            ))}
                        </Dropdown.Menu>
                    </>
                }
            </Dropdown.Menu>
        </Dropdown>
    </>
}

export default observer(FieldSelector);
