import {observable} from "mobx";
import api from "../../Services/Api";
import {TemplateWithFieldsDto} from "../../Entities/Dtos";
import TemplateStore from "./TemplateStore";
import TemplateFieldStore from "./TemplateFieldStore";

class TemplatePageStore {
    @observable id?: string;
    @observable template?: TemplateStore;
    @observable isLoading: boolean = false;
    @observable isLoaded: boolean = false;

    async init(id: string) {
        this.id = id;
        await this.load();
    }

    cleanup() {
        this.id = undefined;
        this.template = undefined;
        this.isLoading = false;
        this.isLoaded = false;
    }

    private async load() {
        this.setIsLoading(true);
        try {
            const result = await api.get(`templateV2/${this.id}`);
            if (result.success) {
                const dto = result.data as TemplateWithFieldsDto;
                const fields = dto.fields.map(f =>
                    new TemplateFieldStore(f.id, f.tag, f.name,
                        f.type, f.tip,
                        f.value, f.isRequired, f.helpUrl, f.possibleValues));
                this.template = new TemplateStore(dto.id,
                    dto.name,
                    dto.categoryPath,
                    fields,
                    dto.requiredImages,
                    dto.requiredPrice,
                    dto.imageUrls,
                    dto.imageRange,
                    () => this.load(),
                    dto.categoryDocumentationLink);
                this.setIsLoaded(true);
            }
        } finally {
            this.setIsLoading(false);
        }
    }

    private setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    private setIsLoaded(value: boolean) {
        this.isLoaded = value;
    }
}

const templatePageStore = new TemplatePageStore();

export default templatePageStore;