import {List, Message} from "semantic-ui-react";
import {InformationPanelItem} from "../Dashboard/InformationPanelItem";
import React from "react";
import UnloadSettingsStorage from "../../Stores/UnloadSettingsStorage";
import storesSettingsStorage from "../../Stores/StoresSettingsStorage";
import UnloadStoreRaw from "./UnloadStoreRaw";
import {observer} from "mobx-react-lite";
import skladStore from "../../Stores/SkladStore";
import StoresNotActiveWarning from "../Shared/StoresNotActiveWarning";

function UnloadStoreList(props: { unload: UnloadSettingsStorage }) {
    const {unload} = props;
    return <InformationPanelItem header={'Склады'}>
        {skladStore.needCheckStores
            ? <Message content={'Выберите склады, товары с которых будут выгружаться в данный XML файл'}/>
            : <StoresNotActiveWarning/>}
        <List>
            {storesSettingsStorage.stores.map(store =>
                <UnloadStoreRaw key={store.id + unload.id} storeName={store.name}
                                unloadStore={unload.getOrCreateUnloadStore(store.id)}/>)}
        </List>
    </InformationPanelItem>
}

export default observer(UnloadStoreList);
