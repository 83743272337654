import React, {useEffect} from 'react';
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {Segment, Sidebar} from 'semantic-ui-react';

import Header from './Components/Header/Header';
import Templates from './Pages/Templates/Templates';
import Dashboard from './Pages/Dashboard/Dashboard';
import ErrorPortal from './Components/ErrorPortal/ErrorPortal';
import SuccessPortal from './Components/SuccessPortal';
import TemplateMapping from "./Pages/TemplateMapping/TemplateMapping";
import UnloadsSettingsPage from "./Pages/Unloads/UnloadsSettingsPage";
import StoresSettingsPage from "./Pages/Stores/StoresSettingsPage";
import AvitoAccountsSettingPage from "./Pages/Avito/AvitoAccountsSettingPage";
import MessengerPage from "./Pages/Messenger/MessengerPage";
import HelpPage from "./Pages/Help/HelpPage";
import OrdersPage from "./Pages/Orders/OrdersPage";
import OrderPage from "./Pages/Orders/Order/OrderPage";
import DictionariesPage from "./Pages/Dictionaries/DictionariesPage";
import websocketEventConsumer from "./Services/WebsocketEventConsumer";
import TemplatesV2Page from "./Pages/Templates/V2/TemplatesV2Page";
import ContextMenu from "./Pages/Templates/V2/ContextMenu/ContextMenu";
import fieldsLookupStorage from "./Stores/FieldsLookupStorage";

const rightSegmentStyle = {
    'border': 'unset',
    'boxShadow': 'unset',
    maxHeight: '100vh',
    minHeight: '90vh',
    overflowY: 'auto',
    marginTop: '0px'
};

function UserRouter() {
    useEffect(() => {
        void fieldsLookupStorage.load();
        return function cleanup() {
            void websocketEventConsumer.stop();
        }
    }, []);
    return (
        <>
            <Sidebar.Pushable as={Segment} style={{overflow: 'hidden'}}>
                <ContextMenu/>
                <Header/>
                <Sidebar.Pusher>
                    <Segment style={rightSegmentStyle}>
                        <ErrorPortal/>
                        <SuccessPortal/>
                        <Switch>
                            <Route path="/" exact>
                                <Redirect to="/home"/>
                            </Route>
                            <Route path="/home">
                                <Dashboard/>
                            </Route>
                            <Route path="/templates">
                                <Templates/>
                            </Route>
                            <Route path="/templatesV2">
                                <TemplatesV2Page/>
                            </Route>
                            <Route path="/templateMapping">
                                <TemplateMapping/>
                            </Route>
                            <Route path="/stores">
                                <StoresSettingsPage/>
                            </Route>
                            <Route path="/unloads">
                                <UnloadsSettingsPage/>
                            </Route>
                            <Route path="/avito">
                                <AvitoAccountsSettingPage/>
                            </Route>
                            <Route path="/dictionaries">
                                <DictionariesPage/>
                            </Route>
                            <Route path="/messenger">
                                <MessengerPage/>
                            </Route>
                            <Route path="/orders" exact>
                                <OrdersPage/>
                            </Route>
                            <Route path={`/orders/:id`}>
                                <OrderPage/>
                            </Route>
                            <Route path="/help">
                                <HelpPage/>
                            </Route>
                        </Switch>
                    </Segment>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </>
    );
}

export default UserRouter;
