import React from "react";
function FormLabel(props: { text: string }) {
    return <label style={{
        display: 'block',
        margin: '0 0 .28571429rem 0',
        fontSize: '.92857143em',
        fontWeight: 700
    }}>{props.text}</label>
}

export default FormLabel;