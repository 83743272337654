import React from "react";
import {observer} from "mobx-react-lite";
import CategoryFieldStore from "./CategoryFieldStore";
import {Button, Checkbox, Divider, Form, FormInput, Grid, Icon} from "semantic-ui-react";
import FieldIdSelector from "../../Pages/Shared/FieldIdSelector";
import categoryLookupsStore from "./CategoryLookupsStore";
import categoryEditorPageStore from "./CategoryEditorPageStore";
import copy from "copy-to-clipboard";

function CategoryFieldsPage(props: { fields: CategoryFieldStore[] }) {
    const {fields} = props;
    return <Grid.Column width={16}>
        <Grid.Row>
            {fields.map(f => <CategoryField key={f.id} field={f}/>)}
        </Grid.Row>
        <Grid.Row>
            <Button basic onClick={() => categoryEditorPageStore.editor!.createField()}>
                <Icon name={'plus'}/> Добавить поле</Button>
            {!categoryEditorPageStore.editor!.hasDefaultFields &&
                <Button basic onClick={() => categoryEditorPageStore.editor!.createDefaultFields()}>
                    Добавить Id, Наименование, Адрес, Остаток, Описание
                </Button>
            }
        </Grid.Row>
    </Grid.Column>
}

const CategoryField = observer((props: { field: CategoryFieldStore }) => {
    const {field} = props;
    return <>
        <Form>
            <Form.Group>
                <Form.Field>
                    <label>Id</label>
                    <input width={3} style={{cursor: 'pointer'}} value={field.id} readOnly
                           onClick={() => copy(field.id)}/>
                </Form.Field>
                <Form.Input required width={3} label={'Тэг'} value={field.tag}
                            onChange={e => field.updateTag(e.target.value)}/>
                <Form.Input required width={4} label={'Имя'} value={field.name}
                            onChange={e => field.updateName(e.target.value)}/>
                <Form.Field required width={4}>
                    <label>Тип</label>
                    <FieldIdSelector fields={categoryLookupsStore.fieldTypes}
                                     onChange={value => field.updateType(value ?? '')} value={field.type}/>
                </Form.Field>
                <Form.Field width={3}>
                    <label>Обязательность</label>
                    <Checkbox checked={field.isRequired}
                              toggle
                              onClick={(_, data) => field.updateIsRequired(data.checked ?? false)}/>
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Input required width={6} label={'Url'}
                            value={field.helpUrl}
                            onChange={e => field.updateHelpUrl(e.target.value)}/>
                <Form.Input required type={'number'} label={'Порядок'}
                            width={2}
                            value={field.order}
                            onChange={e => field.updateOrder(Number(e.target.value ?? 0))}/>
                <Form.Input width={4} label={'Значение по умолчанию'}
                            value={field.defaultValue}
                            onChange={e => field.updateDefaultValue(e.target.value ?? '')}/>
                <FormInput width={4}
                           label={'Подсказка'}
                           value={field.tip}
                           onChange={e => field.updateTip(e.target.value)}/>
            </Form.Group>
            <Form.Group>
                <Form.TextArea value={field.possibleValues}
                               onChange={(_, data) => field.updatePossibleValues(data.value as string)}
                               width={5}
                               label='Возможные значения'/>
                {field.type === 'MultiSelectString' &&
                    <FormInput required width={3}
                               label={'Тэг перечислителя (Option)'}
                               value={field.multiplyTag}
                               onChange={e => field.updateMultiplyTag(e.target.value)}/>}
            </Form.Group>
            <Form.Group>
                <Form.Field type={'submit'}>
                    <Button loading={field.isSaving} floated={'right'}
                            basic
                            color={'teal'}
                            disabled={!field.canSave}
                            onClick={() => field.save()}>
                        <Icon name={'save'}/>Сохранить</Button>
                </Form.Field>
                <Form.Field>
                    <Button fluid color={'red'} disabled={field.isSaving}
                            basic
                            onClick={() => field.delete()}>
                        <Icon name={'trash'}/>Удалить</Button>
                </Form.Field>
            </Form.Group>
        </Form>
        <Divider/>
    </>
})

export default observer(CategoryFieldsPage);