import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Container} from "semantic-ui-react";
import UsersPage from "./Users/UsersPage";
import authStore from "../Stores/AuthStore";
import AdminLoginPage from "./Login/AdminLoginPage";
import AdminMenu from "./Menu/AdminMenu";
import TokenConverter from "./TokenConverter";
import AnalyticsBlock from "./AnalyticsBlock";
import StockWebhookTester from "./EventTester";
import AdminTemplateV2Page from "./TemplateV2/AdminTemplateV2Page";
import SubscriptionChecker from "./SubscriptionChecker/SubscriptionChecker";
import ErrorPortal from "../Components/ErrorPortal/ErrorPortal";
import SuccessPortal from "../Components/SuccessPortal";

export const routeName = "ninjaPortal";

function AdminRouter() {
    return (
        <Container>
            {authStore.isAdmin &&
                <>
                    <SuccessPortal/>
                    <ErrorPortal/>
                    <AdminMenu/>
                    <TokenConverter/>
                    <StockWebhookTester/>
                    <AnalyticsBlock/>
                    <SubscriptionChecker />
                </>
            }
            <Switch>
                <Route path={`/${routeName}/templatesV2`}>
                    {authStore.isAdmin ? <AdminTemplateV2Page/> : <Redirect to={`/${routeName}`}/>}
                </Route>
                <Route path={`/${routeName}/users`}>
                    {authStore.isAdmin ? <UsersPage/> : <Redirect to={`/${routeName}`}/>}
                </Route>
                <Route exact path={`/${routeName}`}>
                    {authStore.isAdmin ? <Redirect to={`/${routeName}/users`}/> : <AdminLoginPage/>}
                </Route>
            </Switch>
        </Container>
    )
}

export default observer(AdminRouter);