import React from "react";
import {observer} from "mobx-react-lite";
import {Form} from "semantic-ui-react";
import CategoryCreator from "./CategoryCreator";
import FieldIdSelector from "../../Pages/Shared/FieldIdSelector";
import categoryLookupsStore from "./CategoryLookupsStore";
import CategoryEditor from "./CategoryEditor";
import store from "./CategoryEditorPageStore";

function CategoryEditorPage() {
    return <Form onSubmit={e => e.preventDefault()}
                 onKeyDown={(e: any) => {
                     if (e.key === 'Enter') {
                         e.preventDefault();
                     }
                 }}>
        <Form.Field>
            <label>Категория</label>
            <FieldIdSelector value={store.category?.id}
                             fields={categoryLookupsStore.categories}
                             search
                             onChange={value => store.init(categoryLookupsStore.getCategoryById(value))}
            />
        </Form.Field>
        {store.editor && <CategoryEditor category={store.editor}/>}
        <CategoryCreator store={store.creator}/>
    </Form>
}

export default observer(CategoryEditorPage);