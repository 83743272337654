import React from 'react'
import {NavLink} from 'react-router-dom'
import {Menu, Sidebar} from 'semantic-ui-react'
import './Style.css';
import {observer} from "mobx-react-lite";
import avitoAccountsSettingsStorage from "../../Stores/AvitoAccountsSettingsStorage";
import authStore from "../../Stores/AuthStore";

function Header() {
    return (
        <>
            <Sidebar
                as={Menu}
                icon
                vertical
                visible
                width='thin'
            >
                <Menu.Item
                    as={NavLink}
                    to="/home"
                    icon='home'
                    content='Главная'
                />
                <Menu.Item
                    as={NavLink}
                    to="/templates"
                    icon='file alternate'
                    content='Шаблоны'
                />
                {authStore.isAdmin && <Menu.Item
                    as={NavLink}
                    to="/templatesV2"
                    icon='file alternate'
                    content='Шаблоны V2'
                />}
                <Menu.Item
                    as={NavLink}
                    to="/templateMapping"
                    icon='folder'
                    content='Группы товаров'
                />
                <Menu.Item
                    as={NavLink}
                    to="/stores"
                    icon='boxes'
                    content='Склады'
                />
                <Menu.Item
                    as={NavLink}
                    to="/unloads"
                    icon='file excel outline'
                    content='XML Файлы'
                />
                <Menu.Item
                    as={NavLink}
                    to="/dictionaries"
                    icon='book'
                    content='Словари'
                />
                <Menu.Item
                    as={NavLink}
                    to="/avito"
                    icon='external alternate'
                    content='Авито'
                />
                {avitoAccountsSettingsStorage.canShowMessenger &&
                    <Menu.Item
                        as={NavLink}
                        to="/messenger"
                        icon='envelope outline'
                        content='Мессенджер'
                    />}
                {avitoAccountsSettingsStorage.canShowOrders &&
                    <Menu.Item
                        as={NavLink}
                        to="/orders"
                        icon='shop'
                        content='Заказы Авито'
                    />}
                <Menu.Item
                    as={NavLink}
                    to="/help"
                    icon='help'
                    content='Помощь'
                />
            </Sidebar>
        </>
    )
}

export default observer(Header);