import {observable} from "mobx";
import api from "../Services/Api";
import skladStore from "./SkladStore";
import fieldsLookupStorage from "./FieldsLookupStorage";

export default class FieldEditorStore {
    @observable name?: string;
    initName?: string;
    @observable isEditing: boolean = false;
    @observable isSaving: boolean = false;
    @observable hasChanges: boolean = false;
    savingUrl: string;
    onSaved: () => Promise<void>
    id?: string;

    constructor(savingUrl: string, onSaved: () => Promise<void>, initName?: string, id?: string) {
        this.savingUrl = savingUrl;
        this.onSaved = onSaved;
        this.name = initName;
        this.initName = initName;
        this.id = id;
    }

    startEditing() {
        this.isEditing = true;
    }

    cancel() {
        this.reset();
    }

    private reset() {
        this.isEditing = false;
        this.name = this.initName;
        this.hasChanges = false;
    }

    async save() {
        if (this.isSaving || !this.name) return;

        this.changeIsSaving(true);
        try {
            const result = await api.post(this.savingUrl, {name: this.name, id: this.id});
            if (result.success) {
                await this.onSaved();
                this.reset();
                void skladStore.load();
                void fieldsLookupStorage.load();
            }
        } finally {
            this.changeIsSaving(false);
        }
    }

    changeName(name: string) {
        this.name = name;
        this.hasChanges = true;
    }

    private changeIsSaving(value: boolean) {
        this.isSaving = value;
    }
}
