import React from 'react';
import {Dimmer, Grid, Header, List, Loader, Placeholder, Segment} from 'semantic-ui-react';
import './Style.css';

interface IInformationPanelItem {
    header?: string;
    children?: React.ReactNode;
    loading?: boolean;
    loadingCaption?: string;
    disableRightPadding?: boolean;
    smallTopPadding?: boolean;
    visible?: boolean;
    listPlaceholder?: boolean;
    listPlaceholderLines?: number;
    className?: string;
    shadows?: boolean;
    hideContentWhenLoading?: boolean;
}

export function InformationPanelItem(props: IInformationPanelItem) {
    if (props.visible !== undefined) {
        if (!props.visible) {
            return <></>;
        }
    }

    let style: any = {};
    if (props.disableRightPadding) {
        style.paddingRight = '0px';
    }
    if (props.smallTopPadding) {
        style.paddingTop = '7px';
    }

    let classes = 'informationPanelItemGrid';
    if (props.shadows === undefined || props.shadows) {
        classes += ' shadow';
    }
    if (props.className) {
        classes += ' ' + props.className;
    }

    function CustomLoader() {
        if (props.listPlaceholder || props.listPlaceholderLines) {
            const lines = props.listPlaceholderLines ?? 5;
            const placeholderLines = [];
            for (let i = 0; i < lines; i++) {
                placeholderLines.push(<Placeholder.Line key={i}/>);
            }
            return <List>
                <Placeholder>
                    {placeholderLines}
                </Placeholder>
            </List>
        }

        return <Dimmer active inverted>
            <Loader size='large'>{props.loadingCaption ?? "Загружаем данные"}</Loader>
        </Dimmer>
    }

    return (
        <Grid.Row>
            <Grid.Column className={classes}>
                {props.header &&
                    <Header className='informationPanelItem' textAlign='center'
                            attached='top'>{props.header}</Header>
                }
                <Segment textAlign='left' attached={props.header ? 'bottom' : false} style={style}>
                    {
                        props.loading ?
                            (props.hideContentWhenLoading ?
                                    <CustomLoader/> :
                                    <>
                                        <CustomLoader/>
                                        {props.children}
                                    </>
                            ) :
                            <>{props.children}</>
                    }
                </Segment>
            </Grid.Column>
        </Grid.Row>
    );
}
