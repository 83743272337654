import React from 'react';
import { Grid } from 'semantic-ui-react';
import TemplateMappingBlock from "./TemplateMappingBlock";

function TemplateMapping() {
    return (
        <>
            <Grid>
                <Grid.Column width='9'>
                    <TemplateMappingBlock />
                </Grid.Column>
            </Grid>
        </>
    )
}

export default TemplateMapping;
